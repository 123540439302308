import logo from "./logo.svg";
import "./App.css";
import LoginFormComponent from "./components/Login";
import HomePage from "./components/Home";

function App() {
  const token = localStorage.getItem("authToken") || false;
  return <>{token === false ? <LoginFormComponent /> : <HomePage />}</>;
}

export default App;
