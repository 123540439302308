import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import useAxiosPost from "../hooks/useAxiosPost";
import { ToastContainer, toast } from "react-toastify";

function UserNamePasswordUpdateModal({
  show,
  handleClose,
  activeUserName,
  activePassword,
  intPkDeviceID,
}) {
  const [
    postApi,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost("devices/updateUserDetails");

  const [staticUsername, setStaticUserName] = useState("");
  const [staticPassword, setStaticPassword] = useState("");
  useEffect(() => {
    console.log({ activeUserName, activePassword });
    setStaticUserName(activeUserName);
    setStaticPassword(activePassword);
  }, []);
  useEffect(() => {
    if (apiError !== null && apiErrorCount != 0) {
      notifyError(apiError);
    }
  }, [apiError, apiErrorCount]);
  useEffect(() => {
    if (apiResponse !== null) {
      notifySuccess(`User Details Updated`);
      handleClose(true);
    }
  }, [apiResponse]);
  const postData = () => {
    const dataToSend = {
      intPkDeviceID,
      staticUsername,
      staticPassword,
    };
    postApi(dataToSend);
  };
  const notifyError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // dispatch(noError(""));
  };
  const notifySuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // dispatch(noError(""));
  };
  return (
    <>
      <Modal show={show} onHide={() => handleClose(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>UserName</Form.Label>
            <Form.Control
              type="text"
              placeholder="UserName"
              value={staticUsername}
              onChange={(e) => setStaticUserName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="text"
              placeholder="Password"
              value={staticPassword}
              onChange={(e) => setStaticPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Button
              variant="success"
              onClick={(e) => {
                e.preventDefault();
                postData();
              }}
            >
              {" "}
              Update
            </Button>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserNamePasswordUpdateModal;
