import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import useAxiosPost from "../hooks/useAxiosPost";
import { ToastContainer, toast } from "react-toastify";

function ServerDetailsUpdateModal({
  show,
  handleClose,
  activeIp,
  activePort,
  intPkDeviceID,
}) {
  const [
    postApi,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost("devices/updateServerDetails");

  const [serverIP, setServerIp] = useState("");
  const [serverPort, setServerPort] = useState("");
  useEffect(() => {
    console.log({ activeIp, activePort });
    setServerIp(activeIp);
    setServerPort(activePort);
  }, []);
  useEffect(() => {
    if (apiError !== null && apiErrorCount != 0) {
      notifyError(apiError);
    }
  }, [apiError, apiErrorCount]);
  useEffect(() => {
    if (apiResponse !== null) {
      notifySuccess(`Server Details Updated`);
      handleClose(true);
    }
  }, [apiResponse]);
  const postData = () => {
    const dataToSend = {
      intPkDeviceID,
      serverIP,
      serverPort,
    };
    postApi(dataToSend);
  };
  const notifyError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // dispatch(noError(""));
  };
  const notifySuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // dispatch(noError(""));
  };
  return (
    <>
      <Modal show={show} onHide={() => handleClose(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Server Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Server IP</Form.Label>
            <Form.Control
              type="text"
              placeholder="Server IP"
              value={serverIP}
              onChange={(e) => setServerIp(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Server Port</Form.Label>
            <Form.Control
              type="text"
              placeholder="Server Port"
              value={serverPort}
              onChange={(e) => setServerPort(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Button
              variant="success"
              onClick={(e) => {
                e.preventDefault();
                postData();
              }}
            >
              {" "}
              Update
            </Button>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ServerDetailsUpdateModal;
