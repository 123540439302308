import React, { useState, useEffect, useLayoutEffect } from "react";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";

import useAxiosPost from "../hooks/useAxiosPost";

const LoginFormComponent = ({}) => {
  const [
    postLoginData,
    loginResponseData,
    loginError,
    loginLoading,
    loginMessage,
    loginErrorCount,
  ] = useAxiosPost("auth/login");

  const loginValidationSchema = yup.object().shape({
    userName: yup.string().required(),
    password: yup.string().required(),
  });

  useEffect(() => {
    if (loginError !== null && loginErrorCount !== 0) {
      notifyError(loginError);
    }
  }, [loginError, loginErrorCount]);
  useEffect(() => {
    if (loginResponseData !== null) {
      //   const { authTokenString, userType } = loginResponseData;
      const responseData = loginResponseData.responseData;
      console.log({ responseData });
      const { token } = responseData;
      if (token) {
        console.log({ token });
        localStorage.setItem("authToken", token);
        window.location.reload();
      } else {
      }
    }
  }, [loginResponseData]);

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = async (event) => {
    event.preventDefault();
    const dataToSend = {
      userName: userName,
      password,
    };
    try {
      const isValid = await loginValidationSchema.validate(dataToSend);
      postLoginData(dataToSend);
    } catch (error) {
      notifyError(error.message);
      console.log(error.message);
    }
  };
  const notifyError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // dispatch(noError(""));
  };

  return (
    <>
      <ToastContainer />
      <Container fluid>
        <Row style={{ marginTop: "50px" }}>
          <Col md={3} lg={3} xl={3} xxl={3} sm={12} xs={12}></Col>
          <Col
            style={{ paddingTop: "80px", background: "black", height: "350px" }}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            sm={12}
            xs={12}
          >
            <Form style={{ maxWidth: "50%", marginLeft: "25%" }}>
              <h3 className="text-white mb-3 ">Login</h3>

              <div className=" text-white">
                <Form.Group controlId="formBasicEmail" className="mb-3">
                  <Form.Control
                    type="text"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Enter UserName"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="mb-3">
                  <Form.Control
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="Enter Password"
                  />
                </Form.Group>

                <Button variant="primary" onClick={handleLogin}>
                  Login
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default LoginFormComponent;
