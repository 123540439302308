import React, { useState, useEffect, useLayoutEffect } from "react";
import { Oval } from "react-loader-spinner";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Table } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";

import useAxiosPost from "../hooks/useAxiosPost";
import { formatDate } from "../helpers/date";
import ServerDetailsUpdateModal from "./UpdateServerDetailsModal";
import UserNamePasswordUpdateModal from "./UpdateUserNameAndPasswordModal";

const HomePage = ({}) => {
  const notifySuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // dispatch(noError(""));
  };
  const [allDevices, setAllDevices] = useState([]);
  const [
    getDevicesData,
    devicesDataResponse,
    devicesDataError,
    devicesDataLoading,
    devicesDataMessage,
    devicesDataErrorCount,
  ] = useAxiosPost("devices/getAllDevices");
  const [
    postApi,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost("devices/removeUserDetails");
  useEffect(() => {
    if (apiResponse != null) {
      notifySuccess(`User Name And Password Removed`);
      getDevicesData({});
    }
  }, [apiResponse]);

  const [
    removeApi,
    removeResponse,
    removeError,
    removeLoading,
    removeMessage,
    removeErrorCount,
  ] = useAxiosPost("devices/removeServerDetails");
  useEffect(() => {
    if (removeResponse != null) {
      notifySuccess(`Server Details Removed`);
      getDevicesData({});
    }
  }, [removeResponse]);

  const [show, setShow] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [activeIp, setActiveIp] = useState();
  const [activePort, setActivePort] = useState("");
  const [intPkDeviceID, setIntPkDeviceID] = useState("");
  const [activeUserName, setActiveUserName] = useState("");
  const [activePassword, setActivePassword] = useState("");
  const handleClose = (isReload) => {
    setShow(false);
    setActiveIp("");
    setActivePort("");
    setIntPkDeviceID("");
    setShowUserModal("");
    setActiveUserName("");
    setActivePassword("");
    if (isReload === true) {
      getDevicesData({});
    }
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  useEffect(() => {
    getDevicesData({});
  }, []);
  useEffect(() => {
    if (devicesDataResponse != null) {
      //   console.log({ data: devicesDataResponse.responseData });
      setAllDevices(devicesDataResponse.responseData || []);
    }
  }, [devicesDataResponse]);

  return (
    <>
      <ToastContainer />
      {show === true ? (
        <ServerDetailsUpdateModal
          activeIp={activeIp}
          activePort={activePort}
          handleClose={handleClose}
          show={show}
          intPkDeviceID={intPkDeviceID}
        />
      ) : null}
      {showUserModal === true ? (
        <UserNamePasswordUpdateModal
          activePassword={activePassword}
          activeUserName={activeUserName}
          handleClose={handleClose}
          show={showUserModal}
          intPkDeviceID={intPkDeviceID}
        />
      ) : null}
      <Container fluid>
        <Row>
          <Col md={1} lg={1} xl={1} xxl={1} sm={12} xs={12}></Col>
          <Col md={10} lg={10} xl={10} xxl={10} sm={12} xs={12}>
            <h3>All Devices</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Device Name</th>
                  <th>Is Test Device</th>
                  <th>Server IP</th>
                  <th>Server Port</th>
                  <th>Update Server</th>
                  <th>Remove</th>

                  <th>Static UserName</th>
                  <th>Static Password</th>
                  <th>Update Details</th>
                  <th>Remove</th>
                  <th>Device Version</th>
                  <th>App Version</th>
                  <th>Brand Name</th>
                  <th>Brand Image</th>
                  <th>Status</th>
                  <th>Expiry Date</th>
                </tr>
              </thead>
              <tbody>
                {devicesDataLoading === true ||
                apiLoading === true ||
                removeLoading === true ? (
                  <>
                    <Oval
                      height={80}
                      width={80}
                      color="#4fa94d"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#4fa94d"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </>
                ) : (
                  <>
                    {allDevices.map((item) => {
                      return (
                        <tr key={Math.random()}>
                          <td>{item.testDeviceName}</td>
                          <td>{item.isTestDevice}</td>
                          <td>{item.serverIP}</td>
                          <td>{item.serverPort}</td>
                          <td>
                            <Button
                              variant="secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveIp(item.serverIP || "");
                                setActivePort(item.serverPort || "");
                                setShow(true);
                                setIntPkDeviceID(item.intPkDeviceID);
                              }}
                            >
                              Update Server Details
                            </Button>
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              onClick={(e) => {
                                e.preventDefault();
                                removeApi({
                                  intPkDeviceID: item.intPkDeviceID,
                                });
                              }}
                            >
                              Remove Server Details
                            </Button>
                          </td>
                          <td>{item.staticUsername}</td>
                          <td>{item.staticPassword}</td>
                          <td>
                            <Button
                              variant="secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveUserName(item.staticUsername || "");
                                setActivePassword(item.staticPassword || "");
                                setShowUserModal(true);
                                setIntPkDeviceID(item.intPkDeviceID);
                              }}
                            >
                              Update User Details
                            </Button>
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              onClick={(e) => {
                                e.preventDefault();
                                postApi({ intPkDeviceID: item.intPkDeviceID });
                              }}
                            >
                              Remove User Details
                            </Button>
                          </td>
                          <td>{item.deviceVersion}</td>
                          <td>{item.appVersion}</td>
                          <td>{item.brandName}</td>
                          <td>
                            <img src={item.iconUrl} style={{ width: "35px" }} />
                          </td>
                          <td>{item.enable === 1 ? "Enable" : "Disable"}</td>
                          <td>{formatDate(item.expiryDate)}</td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default HomePage;
