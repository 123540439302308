import React, { useState } from "react";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL || "http://localhost:5013/api/v1";

const authTokenString = localStorage.getItem("authToken");

const useAxiosPost = (url) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [errorCount, setErrorCount] = useState(0);
  const API_URL = baseURL;

  const postData = async (body) => {
    setIsLoading(true);
    const axiosBody = {
      ...body,
      authTokenString,
    };
    try {
      const response = await axios.post(`${API_URL}/${url}`, axiosBody, {
        headers: {
          Authorization: `Bearer ${authTokenString}`,
        },
      });
      setData(response.data);
      setError(null);
    } catch (error) {
      setError(error.response?.data?.message || "Server Error");
      setErrorCount((prevCount) => prevCount + 1);
      setData(null);
    } finally {
      setIsLoading(false);
    }
  };

  return [postData, data, error, isLoading, message, errorCount];
};

export default useAxiosPost;
